import { useState, useEffect } from "react";

const useAudioPlayer = () => {
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    const audio = document.getElementById("audio");

    const setAudioData = () => {
    }

    // DOM listeners: update React state on DOM events
    audio.addEventListener("loadeddata", setAudioData);

    //audio.addEventListener("timeupdate", setAudioTime);

    // React state listeners: update DOM on React state changes
    playing ? audio.play() : audio.pause();

    // effect cleanup
    return () => {
      audio.removeEventListener("loadeddata", setAudioData);
    }
  });

  return {
    playing,
    setPlaying,
  }
}

export default useAudioPlayer;