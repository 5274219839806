import React, { Fragment, useEffect, useContext } from "react"

import { NewsContext } from "../../contexts/newsContext"

import Loader from '../../components/loader';
import AdminLayout from "../../layouts/admin"
import SEO from "../../components/seo"

import useAudioPlayer from "../../components/useAudioPlayer"

import { Empty, Button } from 'antd';

const AdminLinksPage = ({ location }) => {
  const { playing, setPlaying } = useAudioPlayer()
  const { newsItem, newsItemError, newsItemLoading, getPageNewsItem } = useContext(NewsContext);
	useEffect(() => {
		getPageNewsItem('adminTestServer');
  }, []);
  
  return (
    <AdminLayout
      path={location.pathname}
      breadcrumbs={[{ link: "/admin/testserver", title: "Test server" }]}
    >
      <SEO title="Admin test server" />

      <audio id="audio">
        <source src="http://www.streamhits.nl:9860/stream/1/" type="audio/mpeg" />
        Your browser does not support the <code>audio</code> element.
      </audio>

     

      <Button
        type="primary"
        onClick={() => setPlaying(!playing)}
      >
        { playing ? 'Stop testserver' : 'Play testserver'}
      </Button>

      <br/><br/>

      { newsItemLoading ?
        <Loader />
      :
        <Fragment>
          { newsItemError || !newsItem ?
            <Empty />
          :
            <Fragment>
              <h1>{newsItem.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: newsItem.text }} />
            </Fragment>
          }

        </Fragment>

      }
    </AdminLayout>
  )
}

export default AdminLinksPage
